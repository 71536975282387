<template>
  <div>
    <GooUpdatePaymentMethod
      v-if="editing"
      :has-payment-method="hasPaymentMethod"
      @cancel="endEditing"
      @updated="onUpdate"
    />

    <GooSpinner v-if="isLoading" />
    <div v-else class="account-settings-grid">
      <div class="account-settings-grid-item">
        <PaymentMethod v-if="paymentMethod" :payment-method="paymentMethod" />
        <span v-else>No payment method yet.</span>
      </div>
      <div class="account-settings-grid-item">
        <ResponsiveEditButton v-if="hasPaymentMethod" @click="startEditing">
          Update Card
        </ResponsiveEditButton>
        <ResponsiveEditButton v-else @click="startEditing">
          Add a Card
        </ResponsiveEditButton>
      </div>
    </div>
  </div>
</template>

<script>
  import { GET_PAYMENT_METHOD } from "@/components/queries";
  import PaymentMethod from "@/components/Settings/components/PaymentMethod";
  import GooUpdatePaymentMethod from "@/goo2/applets/goo-update-payment-method/GooUpdatePaymentMethod";
  import ResponsiveEditButton from "@/components/Settings/components/ResponsiveEditButton";

  export default {
    name: "AccountSettingPaymentMethod",
    components: { ResponsiveEditButton, GooUpdatePaymentMethod, PaymentMethod },
    data () {
      return {
        editing: false,
        paymentMethod: null
      };
    },
    computed: {
      isLoading () {
        return this.$apollo.queries.paymentMethod.loading;
      },
      hasPaymentMethod () {
        return this.paymentMethod !== null;
      }
    },
    apollo: {
      paymentMethod: GET_PAYMENT_METHOD
    },
    methods: {
      startEditing () {
        this.editing = true;
      },
      endEditing () {
        this.editing = false;
      },
      onUpdate () {
        this.endEditing();
        this.$apollo.queries.paymentMethod.refresh();
      }
    }
  };
</script>

<style scoped>

</style>
