<template>
  <div>
    <AccountSettingNameForm
      v-if="updating"
      :user="user"
      @updated="onUpdate"
      @cancel="onCancel"
    />
    <div v-else class="account-settings-grid">
      <div class="account-settings-grid-item">
        <p class="is-marginless">
          {{ user.firstName }} {{ user.lastName }}
        </p>
      </div>
      <div class="account-settings-grid-item">
        <ResponsiveEditButton @click="updating = true">
          Update Name
        </ResponsiveEditButton>
      </div>
    </div>
  </div>
</template>

<script>
  import AccountSettingNameForm from "./AccountSettingNameForm";
  import ResponsiveEditButton from "@/components/Settings/components/ResponsiveEditButton";
  export default {
    name: "AccountSettingName",
    components: { ResponsiveEditButton, AccountSettingNameForm },
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        updating: false
      };
    },
    methods: {
      onUpdate () {
        this.updating = false;
      },
      onCancel () {
        this.updating = false;
      }
    }
  };
</script>

<style lang="scss">

</style>
