<template>
  <div>
    <fieldset>
      <h3 class="goo-dialog-heading heading-3">
        Sign up for text notifications
      </h3>
      <p>Enter your phone number to receive a verification code.</p>

      <div class="goo-input-group">
        <label for="phone-number" class="is-sr-only">Phone number</label>
        <goo-phone id="phone-number" v-model="phoneNumber" placeholder="Phone number" />
      </div>
      <GooFlash v-if="error" type="error">
        {{ error }}
      </GooFlash>
    </fieldset>
    <div class="goo-button-group">
      <goo-submit class="goo-button is-primary is-full-width" :aria-disabled="!nextEnabled" :class="{'is-disabled' : !nextEnabled}" :loading="loading" @click="onSubmit">
        Send Code
      </goo-submit>
    </div>
  </div>
</template>

<script>
  import { START_PHONE_VERIFICATION } from "../mutations";
  import GooFlash from "@/goo2/components/goo-flash/GooFlash";
  import { parsePhoneNumberFromString } from "libphonenumber-js";

  export default {
    name: "GooPhoneVerificationDialogStep1",
    components: { GooFlash },
    data () {
      return {
        error: null,
        loading: false,
        phoneNumber: ""
      };
    },
    computed: {
      parsedNumber () {
        return parsePhoneNumberFromString(this.phoneNumber, "US");
      },
      nextEnabled () {
        const number = this.parsedNumber;

        return (
          number !== undefined &&
          number.isValid() &&
          number.country === "US" &&
          number.ext === undefined
        );
      }
    },
    methods: {
      async onSubmit () {
        this.error = null;
        this.loading = true;

        try {
          const { data: { result } } = await this.$apollo.mutate({
            mutation: START_PHONE_VERIFICATION,
            variables: {
              phoneNumber: this.parsedNumber.format("E.164")
            }
          });

          if (result.ok) {
            this.$emit("complete", result.phoneVerification);
          } else {
            this.error = result.error;
          }
        } catch (e) {
          this.error = "An unknown error has occurred. Please try again.";
        } finally {
          this.loading = false;
        }
      }
    }
  };
</script>

<style lang="scss">

</style>
