<template>
  <goo-search
    class="goo-timezone-picker"
    :items="searchResults"
    :show-on-focus="true"
    :min-search-length="0"
    :selected-value="selectedSearchValue"
    v-bind="$attrs"
    @search="onSearch"
    @selectResult="onSelect"
  >
    <template slot-scope="{ result }">
      <div class="goo-timezone-picker-item has-text-left" :class="itemClassName(result)">
        <div class="goo-timezone-picker-item-content">
          <div class="goo-search-result-title">
            {{ result.name }} <span v-if="isDetectedTimezoneItem(result)" class="has-text-bold">(Detected time zone)</span>
          </div>
          <div class="goo-search-result-subtitle">
            {{ result.country }}
          </div>
          <div class="goo-search-result-subtitle">
            {{ timeInTimezone(result.value) }} ({{ result.gmtOffset }})
          </div>
        </div>
        <div v-if="isDetectedTimezoneItem(result)" class="goo-timezone-picker-item-icon">
          <goo-icon name="compass" class="is-primary" />
        </div>
      </div>
    </template>
  </goo-search>
</template>

<script>
  import { GET_TIMEZONES } from "./queries";

  export default {
    name: "GooTimezonePicker",
    props: {
      value: {
        type: String,
        default: null
      },
      autoDetect: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        timezones: [],
        currentSearch: null,
        filteredTimezones: [],
        selectedResult: null,
        currentTime: new Date(),
        timeUpdateTimeout: null
      };
    },
    apollo: {
      timezones: {
        query: GET_TIMEZONES,
        // Ensure that the query gets executed only once per session as the
        // data is very unlikely to change.
        fetchPolicy: "cache-first"
      }
    },
    computed: {
      selectedSearchValue () {
        const selected = this.timezones.find((t) => t.identifier === this.value);
        if (selected) {
          return `${selected.name} - ${selected.country}`;
        }

        return null;
      },
      rankedTimezones () {
        /* eslint-disable no-unused-vars */
        return this.timezones.slice().sort((a, b) => {
          return (a === this.detectedTimezone) ? -1 : 0;
        });
      },
      searchResults () {
        if (!this.currentSearch) {
          return this.rankedTimezones;
        }

        return this.rankedTimezones.filter(timezone => {
          return (
            timezone.name.toLowerCase().includes(this.currentSearch.toLowerCase()) ||
            timezone.country.toLowerCase().includes(this.currentSearch.toLowerCase())
          );
        });
      },
      detectedTimezone () {
        if (!this.autoDetect) {
          return null;
        }

        const formatter = Intl.DateTimeFormat();
        const detected = formatter.resolvedOptions().timeZone;
        if (!detected) {
          return null;
        }

        return this.timezones.find(timezone => timezone.identifier === detected);
      }
    },
    mounted () {
      clearInterval(this.timeUpdateTimeout);
      this.timeUpdateTimeout = setInterval(() => {
        this.currentTime = new Date();
      }, 500);
    },
    destroyed () {
      clearInterval(this.timeUpdateTimeout);
    },
    methods: {
      onSearch (search) {
        this.currentSearch = search;
        this.$emit("input", null);
      },
      onSelect (result) {
        this.$emit("input", result.identifier);
      },
      timeInTimezone (timezone) {
        try {
          const formatter = new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
            timeZone: timezone
          });

          return formatter.format(this.currentTime);
        } catch (e) {
          return null;
        }
      },
      isDetectedTimezoneItem (item) {
        return item === this.detectedTimezone;
      },
      itemClassName (item) {
        return {
          "is-sticky": this.isDetectedTimezoneItem(item)
        };
      }
    }
  };
</script>

<style lang="scss">
  .goo-timezone-picker {
    // TODO: Goo should not assume that a CSS reset is present.
    // Move the following to `goo-search`.
    ul, li {
      margin: 0;
    }

    .icon-button {
      border: none;
      background: none;
      padding: 0;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &-icon {
        margin-left: auto;
        padding-left: 1rem;
      }

      &.is-sticky {
        padding-bottom: 0.5rem;
        border-bottom: 1px solid rgba(#000, 0.2);
        margin-bottom: - 0.5rem;
      }
    }
  }
</style>
