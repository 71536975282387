<template>
  <transition name="goo-modal-transition" appear>
    <goo-modal :active="true" @close="onModalClose">
      <div class="goo-dialog is-alert goo-form-dialog">
        <goo-close-button @click="onModalClose" />
        <GooPhoneVerificationDialogStep1
          v-if="step === 1"
          @complete="onStep1Complete"
        />
        <GooPhoneVerificationDialogStep2
          v-if="step === 2"
          :phone-verification="phoneVerification"
          @complete="onStep2Complete"
          @cancel="onStep2Cancel"
        />
      </div>
    </goo-modal>
  </transition>
</template>

<script>
  import GooPhoneVerificationDialogStep1 from "./steps/GooPhoneVerificationDialogStep1";
  import GooPhoneVerificationDialogStep2 from "./steps/GooPhoneVerificationDialogStep2";

  export default {
    name: "GooPhoneVerificationDialog",
    components: {
      GooPhoneVerificationDialogStep1,
      GooPhoneVerificationDialogStep2
    },
    data () {
      return {
        step: 1
      };
    },
    methods: {
      onStep1Complete (phoneVerification) {
        this.phoneVerification = phoneVerification;
        this.step = 2;
      },
      onStep2Complete () {
        this.step = 2;
        this.$emit("verified", this.phoneVerification.phoneNumber);
      },
      onStep2Cancel () {
        this.step = 1;
      },
      onModalClose () {
        this.$emit("cancel");
      }
    }
  };
</script>

<style lang="scss">

</style>
