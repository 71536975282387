<template>
  <form @submit.prevent="onSubmit">
    <fieldset>
      <legend class="is-sr-only">
        Account Name
      </legend>
      <div class="goo-input-group">
        <div class="goo-control is-expanded">
          <label for="current-password" class="goo-label">Current Password</label>
          <input id="current-password" v-model="input.currentPassword" type="password" name="current-password" class="goo-input">
        </div>
        <div class="goo-control is-expanded">
          <label for="new-password" class="goo-label">New Password</label>
          <input id="new-password" v-model="input.newPassword" type="password" name="new-password" class="goo-input">
          <p class="is-gray-extra-dark-text-color is-marginless">
            8 characters or longer.
          </p>
        </div>
        <div class="goo-control is-expanded">
          <label for="confirm-new-password" class="goo-label">Confirm New Password</label>
          <input id="confirm-new-password" v-model="input.passwordConfirmation" type="password" name="confirm-new-password" class="goo-input">
        </div>
      </div>

      <GooFlash v-if="error" type="error">
        {{ error }}
      </GooFlash>
    </fieldset>
    <div class="goo-button-group is-right">
      <button type="button" class="goo-button is-primary is-outlined" @click="cancel">
        Cancel
      </button>
      <goo-submit class="goo-button is-primary" :disabled="!enableSubmitButton" :loading="saving" loading-text="Saving...">
        Save
      </goo-submit>
    </div>
  </form>
</template>

<script>
  import { UPDATE_ACCOUNT_PASSWORD } from "@/components/Settings/tabs/AccountSettings/mutations";
  import GooFlash from "@/goo2/components/goo-flash/GooFlash";

  export default {
    name: "AccountSettingPasswordForm",
    components: { GooFlash },
    data () {
      return {
        error: null,
        saving: false,
        input: {
          currentPassword: "",
          newPassword: "",
          passwordConfirmation: ""
        }
      };
    },
    computed: {
      enableSubmitButton () {
        return (
          this.input.currentPassword.length > 0 &&
          this.input.newPassword.length > 0 &&
          this.input.newPassword === this.input.passwordConfirmation
        );
      }
    },
    methods: {
      async onSubmit () {
        this.error = null;
        this.saving = true;

        try {
          const { data: { result } } = await this.$apollo.mutate({
            mutation: UPDATE_ACCOUNT_PASSWORD,
            variables: {
              input: {
                newPassword: this.input.newPassword,
                currentPassword: this.input.currentPassword
              }
            }
          });

          if (result.ok) {
            this.$emit("updated");
          } else {
            this.error = result.error;
          }
        } finally {
          this.saving = false;
        }
      },
      cancel () {
        this.$emit("cancel");
      }
    }
  };
</script>

<style lang="scss">

</style>
