<template>
  <div class="setting-group">
    <div class="setting-group-label">
      <label class="heading-4">{{ label }}</label>
    </div>
    <div class="setting-group-content">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: "SettingGroup",
    props: {
      label: {
        type: String,
        required: true
      }
    }
  };
</script>

<style lang="scss">
  .setting-group {
    margin: 0 0 2.5rem;
    max-width: 600px;

    &-label {
      border-bottom: 1px solid #ebebeb;
      margin-bottom: 1rem;
      padding: 0 0 0.5rem 0;
    }
  }
</style>
