<template>
  <form @submit.prevent="onSubmit">
    <fieldset>
      <div class="goo-input-group">
        <label class="is-sr-only" for="timezone-picker">Time zone</label>
        <GooTimezonePicker id="timezone-picker" v-model="timezone" auto-detect />
      </div>
    </fieldset>

    <div class="goo-button-group is-right">
      <button type="button" class="goo-button is-primary is-outlined" @click="cancel">
        Cancel
      </button>
      <goo-submit class="goo-button is-primary" :loading="saving" :disabled="!enableSubmitButton" loading-text="Saving...">
        Save
      </goo-submit>
    </div>
  </form>
</template>

<script>
  import GooTimezonePicker from "@/goo2/applets/goo-timezone-picker/GooTimezonePicker";
  import { UPDATE_ACCOUNT_TIMEZONE } from "@/components/Settings/tabs/AccountSettings/mutations";

  export default {
    name: "AccountSettingTimezoneForm",
    components: { GooTimezonePicker },
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        timezone: null,
        saving: false
      };
    },
    computed: {
      enableSubmitButton () {
        return (
          this.timezone != null
        );
      }
    },
    mounted () {
      this.timezone = this.user.timezone;
    },
    methods: {
      async onSubmit () {
        this.saving = true;

        try {
          const { data: { result } } = await this.$apollo.mutate({
            mutation: UPDATE_ACCOUNT_TIMEZONE,
            variables: {
              input: {
                timezone: this.timezone
              }
            }
          });

          if (result.ok) {
            this.$emit("updated");
          }
        } finally {
          this.saving = false;
        }
      },
      cancel () {
        this.$emit("cancel");
      }
    }
  };
</script>

<style lang="scss">

</style>
