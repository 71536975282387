import gql from "graphql-tag";

export const UPDATE_ACCOUNT_NAME = gql`
  mutation UpdateAccountName($input: UpdateAccountNameInput!) {
    result: updateAccountName(input: $input) {
      ok
      error
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export const UPDATE_ACCOUNT_EMAIL = gql`
  mutation UpdateAccountEmail($input: UpdateAccountEmailAddressInput!) {
    result: updateAccountEmailAddress(input: $input) {
      ok
      error
      user {
        id
        email
      }
    }
  }
`;

export const UPDATE_ACCOUNT_PASSWORD = gql`
  mutation UpdateAccountPassword($input: UpdateAccountPasswordInput!) {
    result: updateAccountPassword(input: $input) {
      ok
      error
    }
  }
`;

export const UPDATE_ACCOUNT_TIMEZONE = gql`
  mutation UpdateAccountTimeZone($input: UpdateAccountTimezoneInput!) {
    result: updateAccountTimezone(input: $input) {
      ok
      error
      user {
        id
        timezone
      }
    }
  }
`;
