<template>
  <div class="account-settings">
    <h1 class="heading-3">
      Account Settings
    </h1>

    <SettingGroup label="Name">
      <AccountSettingName :user="user" />
    </SettingGroup>

    <SettingGroup label="Email">
      <AccountSettingEmail :user="user" />
    </SettingGroup>

    <SettingGroup label="Time zone">
      <AccountSettingTimezone :user="user" />
    </SettingGroup>

    <SettingGroup label="Text notifications">
      <AccountSettingPhoneNumber :user="user" @updated="onUpdate" />
    </SettingGroup>

    <SettingGroup label="Password">
      <AccountSettingPassword />
    </SettingGroup>

    <SettingGroup label="Payment method">
      <AccountSettingPaymentMethod />
    </SettingGroup>
  </div>
</template>

<script>
  import { GET_USER } from "@/components/queries";

  import SettingGroup from "./components/SettingGroup";
  import AccountSettingName from "./components/AccountSettingName";
  import AccountSettingEmail from "./components/AccountSettingEmail";
  import AccountSettingPassword from "./components/AccountSettingPassword";
  import AccountSettingTimezone from "./components/AccountSettingTimezone";
  import AccountSettingPhoneNumber from "./components/AccountSettingPhoneNumber";
  import AccountSettingPaymentMethod from "./components/AccountSettingPaymentMethod";

  export default {
    name: "AccountSettingsScreen",
    components: {
      AccountSettingTimezone,
      SettingGroup,
      AccountSettingName,
      AccountSettingEmail,
      AccountSettingPassword,
      AccountSettingPhoneNumber,
      AccountSettingPaymentMethod
    },
    props: {
      user: {
        type: Object,
        default: null
      }
    },
    methods: {
      onUpdate () {
        this.$apollo.query({
          query: GET_USER
        }).then(() => {
          this.showPhoneVerificationModal = false;
        });
      }
    }
  };
</script>

<style lang="scss">
  .account-settings {
    &-grid {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &-item:not(:last-of-type) {
        margin: 0 16px 0 0;
      }
    }
  }
</style>
