import gql from "graphql-tag";

export const GET_TIMEZONES = gql`
  query GetTimeZones {
    timezones {
      identifier
      name
      country
      gmtOffset
    }
  }
`;
