<template>
  <form @submit.prevent="onSubmit">
    <fieldset>
      <legend class="is-sr-only">
        Account Name
      </legend>
      <div class="goo-input-group is-inline-medium">
        <div class="goo-control is-expanded">
          <label for="firstName" class="goo-label">First name</label>
          <input id="firstName" v-model="editing.firstName" type="text" name="firstName" class="goo-input">
        </div>
        <div class="goo-control is-expanded">
          <label for="lastName" class="goo-label">Last name</label>
          <input id="lastName" v-model="editing.lastName" type="text" name="lastName" class="goo-input">
        </div>
      </div>
    </fieldset>
    <div class="goo-button-group is-right">
      <button type="button" class="goo-button is-primary is-outlined" @click="cancel">
        Cancel
      </button>
      <goo-submit class="goo-button is-primary" :loading="saving" loading-text="Saving...">
        Save
      </goo-submit>
    </div>
  </form>
</template>

<script>
  import { UPDATE_ACCOUNT_NAME } from "../mutations";

  export default {
    name: "AccountSettingNameForm",
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        editing: {},
        saving: false
      };
    },
    mounted () {
      this.editing = { ...this.user };
    },
    methods: {
      cancel () {
        this.$emit("cancel");
      },
      async onSubmit () {
        this.saving = true;

        try {
          const { data: { result } } = await this.$apollo.mutate({
            mutation: UPDATE_ACCOUNT_NAME,
            variables: {
              input: {
                firstName: this.editing.firstName,
                lastName: this.editing.lastName
              }
            }
          });

          if (result.ok) {
            this.$emit("updated");
          }
        } finally {
          this.saving = false;
        }
      }
    }
  };
</script>

<style lang="scss">

</style>
