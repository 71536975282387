<template>
  <form @submit.prevent="onSubmit">
    <fieldset>
      <div class="goo-input-group">
        <label class="is-sr-only" for="email">New email</label>
        <div class="goo-control has-icons-left">
          <input id="email" v-model="input.email" placeholder="New email" class="goo-input" type="email" name="email">
          <span class="icon is-left"><goo-icon name="envelope" /></span>
        </div>
      </div>
    </fieldset>

    <GooFlash v-if="error" type="error">
      {{ error }}
    </GooFlash>

    <div class="goo-button-group is-right">
      <button type="button" class="goo-button is-primary is-outlined" @click="cancel">
        Cancel
      </button>
      <goo-submit class="goo-button is-primary" :loading="saving" :disabled="!enableSubmitButton" loading-text="Saving...">
        Save
      </goo-submit>
    </div>
  </form>
</template>

<script>
  import { UPDATE_ACCOUNT_EMAIL } from "@/components/Settings/tabs/AccountSettings/mutations";
  import GooFlash from "@/goo2/components/goo-flash/GooFlash";

  const EMAIL_REGEX = /^[^@\s]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]+$/;

  export default {
    name: "AccountSettingEmailForm",
    components: { GooFlash },
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        error: null,
        saving: false,
        input: {
          email: ""
        }
      };
    },
    computed: {
      enableSubmitButton () {
        return (
          this.input.email !== this.user.email &&
          EMAIL_REGEX.test(this.input.email)
        );
      }
    },
    mounted () {
      this.input = Object.assign({}, { email: this.user.email });
    },
    methods: {
      cancel () {
        this.$emit("cancel");
      },
      async onSubmit () {
        this.saving = true;

        try {
          const { data: { result } } = await this.$apollo.mutate({
            mutation: UPDATE_ACCOUNT_EMAIL,
            variables: {
              input: this.input
            }
          });

          if (result.ok) {
            this.$emit("updated");
          } else {
            this.error = result.error;
          }
        } finally {
          this.saving = false;
        }
      }
    }
  };
</script>

<style lang="scss">

</style>
