<template>
  <div>
    <AccountSettingEmailForm
      v-if="updating"
      :user="user"
      @updated="onUpdate"
      @cancel="onCancel"
    />
    <div v-else class="account-settings-grid">
      <div class="account-settings-grid-item">
        {{ user.email }}
      </div>
      <div class="account-settings-grid-item">
        <ResponsiveEditButton @click="updating = true">
          Update Email
        </ResponsiveEditButton>
      </div>
    </div>
  </div>
</template>

<script>
  import AccountSettingEmailForm from "./AccountSettingEmailForm";
  import ResponsiveEditButton from "@/components/Settings/components/ResponsiveEditButton";

  export default {
    name: "AccountSettingEmail",
    components: { ResponsiveEditButton, AccountSettingEmailForm },
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        updating: false
      };
    },
    methods: {
      onUpdate () {
        this.updating = false;
      },
      onCancel () {
        this.updating = false;
      }
    }
  };
</script>

<style lang="scss">

</style>
