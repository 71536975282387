<template>
  <div class="account-setting-phone-number">
    <div class="account-settings-grid">
      <div class="account-settings-grid-item">
        <template v-if="user.phoneNumber">
          <div class="account-setting-phone-number-confirmed">
            <goo-icon name="circle-check-outline" type="is-primary" width="32" height="32" />
            <div>
              You are receiving notifications at<br>
              {{ user.phoneNumber | formatPhoneNumber }}
            </div>
          </div>
        </template>
        <template v-else>
          You are not receiving notifications.
        </template>
      </div>
      <div class="account-settings-grid-item">
        <ResponsiveEditButton @click="onUpdateNumberClick">
          Update Number
        </ResponsiveEditButton>
      </div>
    </div>
    <GooPhoneVerificationDialog
      v-if="showPhoneVerificationModal"
      @cancel="onPhoneVerificationCancel"
      @verified="onVerify"
    />
  </div>
</template>

<script>
  import { formatPhoneNumber } from "@gooroo-life/goo-formatters";
  import { GooPhoneVerificationDialog } from "@/goo2/applets";
  import ResponsiveEditButton from "@/components/Settings/components/ResponsiveEditButton";

  export default {
    name: "AccountSettingPhoneNumber",
    components: { ResponsiveEditButton, GooPhoneVerificationDialog },
    filters: {
      formatPhoneNumber
    },
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        showPhoneVerificationModal: false
      };
    },
    methods: {
      onUpdateNumberClick () {
        this.showPhoneVerificationModal = true;
      },
      onPhoneVerificationCancel () {
        this.showPhoneVerificationModal = false;
      },
      onVerify () {
        this.showPhoneVerificationModal = false;
        this.$emit("updated");
      }
    }
  };
</script>

<style lang="scss">
  .account-setting-phone-number {
    &-confirmed {
      display: flex;
      flex-direction: row;
      align-items: center;

      .goo-icon {
        margin: 0 8px 0 0;
      }
    }
  }
</style>
