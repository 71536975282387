<template>
  <div>
    <AccountSettingPasswordForm
      v-if="editing"
      @cancel="endEditing"
      @updated="endEditing"
    />
    <div v-else class="account-settings-grid">
      <div class="account-settings-grid-item">
        <button class="goo-button is-primary is-outlined" @click="startEditing">
          Change Password
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import AccountSettingPasswordForm from "./AccountSettingPasswordForm";

  export default {
    name: "AccountSettingPassword",
    components: { AccountSettingPasswordForm },
    data () {
      return {
        editing: false
      };
    },
    methods: {
      startEditing () {
        this.editing = true;
      },
      endEditing () {
        this.editing = false;
      }
    }
  };
</script>

<style lang="scss">

</style>
