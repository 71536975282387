import gql from "graphql-tag";

export const START_PHONE_VERIFICATION = gql`
  mutation StartPhoneVerification($phoneNumber: String!) {
    result: startPhoneVerification(phoneNumber: $phoneNumber) {
      ok
      error
      phoneVerification {
        token
        phoneNumber
        expiresAt
      }
    }
  }
`;

export const VERIFY_PHONE_NUMBER = gql`
  mutation VerifyPhoneNumber($token: String!, $verificationCode: String!) {
    result: verifyPhoneNumber(token: $token, verificationCode: $verificationCode) {
      ok
      error
    }
  }
`;
