<template>
  <div>
    <AccountSettingTimezoneForm
      v-if="editing"
      :user="user"
      @cancel="editing = false"
      @updated="editing = false"
    />
    <div v-else class="account-settings-grid">
      <div class="account-settings-grid-item">
        <template v-if="user.timezone">
          {{ formatTimezone(user.timezone) }}
        </template>
        <template v-else>
          You do not have a time zone set.
        </template>
      </div>
      <div class="account-settings-grid-item">
        <ResponsiveEditButton @click="editing = true">
          Set Time Zone
        </ResponsiveEditButton>
      </div>
    </div>
  </div>
</template>

<script>
  import { GET_TIMEZONES } from "@/components/queries";
  import AccountSettingTimezoneForm from "./AccountSettingTimezoneForm";
  import ResponsiveEditButton from "@/components/Settings/components/ResponsiveEditButton";

  export default {
    name: "AccountSettingTimezone",
    components: { ResponsiveEditButton, AccountSettingTimezoneForm },
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        timezones: [],
        editing: false
      };
    },
    apollo: {
      timezones: GET_TIMEZONES
    },
    methods: {
      formatTimezone (identifier) {
        const timezone = this.timezones.find((tz) => tz.identifier === identifier);
        return (timezone === undefined) ? identifier : `${timezone.name} - ${timezone.country}`;
      }
    }
  };
</script>

<style lang="scss">

</style>
