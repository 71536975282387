<template>
  <div>
    <fieldset>
      <h3 class="goo-dialog-heading heading-3">
        Verify phone number
      </h3>
      <p>
        Enter the verification code sent to<br>
        {{ phoneNumber }}
      </p>

      <div class="goo-input-group">
        <label for="verification-code" class="is-sr-only">Verification code</label>
        <goo-verification-code id="verification-code" v-model="code" />
      </div>

      <GooFlash v-if="error" type="error">
        {{ error }}
      </GooFlash>
    </fieldset>

    <div class="goo-button-group is-equal-width">
      <button type="button" class="goo-button is-primary is-outlined" @click="onBack">
        Back
      </button>

      <goo-submit class="goo-button is-primary is-full-width" :aria-disabled="!nextEnabled" :class="{'is-disabled' : !nextEnabled}" :loading="loading" @click="onSubmit">
        Verify
      </goo-submit>
    </div>
  </div>
</template>

<script>
  import { parsePhoneNumberFromString } from "libphonenumber-js";
  import GooFlash from "@/goo2/components/goo-flash/GooFlash";
  import { VERIFY_PHONE_NUMBER } from "../mutations";

  export default {
    name: "GooPhoneVerificationDialogStep2",
    components: { GooFlash },
    props: {
      phoneVerification: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        code: "",
        error: null,
        loading: false
      };
    },
    computed: {
      phoneNumber () {
        const number = parsePhoneNumberFromString(this.phoneVerification.phoneNumber);
        if (number.country === "US") {
          return number.format("NATIONAL");
        }

        return number.format("INTERNATIONAL");
      },
      nextEnabled () {
        return this.code.length === 4;
      }
    },
    methods: {
      onBack () {
        this.$emit("cancel");
      },
      async onSubmit () {
        this.error = null;
        this.loading = true;

        try {
          const { data: { result } } = await this.$apollo.mutate({
            mutation: VERIFY_PHONE_NUMBER,
            variables: {
              verificationCode: this.code,
              token: this.phoneVerification.token
            }
          });

          if (result.ok) {
            this.$emit("complete");
          } else {
            this.error = result.error;
          }
        } catch (e) {
          this.error = "An unknown error has occurred. Please try again.";
        } finally {
          this.loading = false;
        }
      }
    }
  };
</script>

<style lang="scss">

</style>
